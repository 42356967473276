import { ISitemapNode } from '@cloudfun/core'

const sitemap: ISitemapNode = {
  icon: 'HomeIcon',
  to: '',
  title: 'Home',
  subNodes: [
    {
      icon: 'HomeIcon',
      to: 'dashboard',
      title: '儀錶板'
    },
    // {
    //   icon: 'fa-columns',
    //   title: '選單安排',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: '/dashboard',
    //       title: '側邊選單',
    //       ignore: true
    //     },
    //     {
    //       icon: '',
    //       to: '/simple-menu/dashboard',
    //       title: '簡易選單',
    //       ignore: true
    //     },
    //     {
    //       icon: '',
    //       to: '/top-menu/dashboard',
    //       title: '上方選單',
    //       ignore: true
    //     }
    //   ]
    // },

    {
      icon: 'SettingsIcon',
      to: 'configuration',
      title: '系統管理',
      subNodes: [
        {
          icon: 'SettingsIcon',
          to: 'configuration',
          title: '系統配置'
        },
        {
          icon: 'ActivityIcon',
          to: 'action-log',
          title: '操作紀錄'
        },
        {
          icon: 'fa-sitemap',
          to: 'IQC-category',
          title: '來料檢驗類別管理'
        },
        {
          icon: 'fa-file',
          to: 'document-flow',
          title: '文件申請流程設定'
        },
      ]
    },
    {
      icon: 'UserIcon',
      to: 'user',
      title: '用戶管理',
      subNodes: [
        {
          icon: 'fa-magic',
          to: 'user-wizard',
          title: '用戶註冊精靈'
        },
        // {
        //   icon: 'UsersIcon',
        //   to: 'role',
        //   title: '角色管理'
        // },
        {
          icon: 'UsersIcon',
          to: 'position',
          title: '職務管理'
        },
        {
          icon: 'UsersIcon',
          to: 'department',
          title: '部門管理'
        },
        {
          icon: 'UserIcon',
          to: 'employee',
          title: '員工管理'
        }
      ]
    },
    {
      icon: 'fa-database',
      to: 'import-info',
      title: '船務',
      subNodes: [
        {
          icon: 'fa-database',
          to: 'import-info',
          title: '退稅資料匯入'
        },
        {
          icon: 'fa-th-large',
          to: 'mapping1',
          title: '匯入資料瀏覽',
          subNodes: [
            {
              icon: 'fa-list',
              to: 'unit-conversion',
              title: '品號換算單位'
            },
            {
              icon: 'fa-list',
              to: 'mapping1',
              title: 'Mapping_1'
            },
            {
              icon: 'fa-list',
              to: 'mapping2',
              title: 'Mapping_2'
            },
            {
              icon: 'fa-list',
              to: 'product-number-purchase-record',
              title: '品號歷史進貨紀錄表'
            },
            {
              icon: 'fa-list',
              to: 'manufacturer-estimated-purchase',
              title: '廠商預計進貨明細'
            },
          ]
        },
        {
          icon: 'fa-table',
          to: 'estimated-shipment',
          title: '訂單出貨管理',
          subNodes: [
            {
              icon: 'fa-table',
              to: 'estimated-shipment',
              title: '訂單預計出貨明細表'
            },
            {
              icon: 'fa-table',
              to: 'shipment',
              title: '出貨紀錄管理'
            },
          ]
        },
        // {
        //   icon: 'UserIcon',
        //   to: 'user-wizard',
        //   title: '人事資料匯入'
        // },
        // {
        //   icon: 'fa-cog',
        //   to: 'user-wizard',
        //   title: '產線機台資料匯入'
        // },
        // {
        //   icon: 'fa-list',
        //   to: 'user-wizard',
        //   title: '退稅資料匯入'
        // },
      ]
    },
    {
      icon: 'fa-database',
      to: 'import-delivery',
      title: '生管',
      subNodes: [
        {
          icon: 'fa-database',
          to: 'import-delivery',
          title: '交期資料匯入'
        },
      ]
    },
    // {
    //   icon: 'fa-th-large',
    //   to: 'mapping1',
    //   title: '匯入資料瀏覽',
    //   subNodes: [
    //     {
    //       icon: 'fa-list',
    //       to: 'unit-conversion',
    //       title: '品號換算單位'
    //     },
    //     {
    //       icon: 'fa-list',
    //       to: 'mapping1',
    //       title: 'Mapping_1'
    //     },
    //     {
    //       icon: 'fa-list',
    //       to: 'mapping2',
    //       title: 'Mapping_2'
    //     },
    //     {
    //       icon: 'fa-list',
    //       to: 'product-number-purchase-record',
    //       title: '品號歷史進貨紀錄表'
    //     },
    //     {
    //       icon: 'fa-list',
    //       to: 'manufacturer-estimated-purchase',
    //       title: '廠商預計進貨明細'
    //     },
    //   ]
    // },
    {
      icon: 'fa-file',
      to: 'document',
      title: '申請文件管理',
      subNodes: [
        // {
        //   icon: 'fa-file',
        //   to: 'document',
        //   title: '建立文件'
        // },
        {
          icon: 'fa-file',
          to: 'document',
          title: '人事',
          subNodes: [
            // {
            //   icon: 'fa-file',
            //   to: 'personnel-assessment-monthly',
            //   title: '人員考核月報表'
            // },
            {
              icon: 'fa-file',
              to: 'employee-change-request',
              title: '員工異動單'
            },
            {
              icon: 'fa-file',
              to: 'personnel-supplementary',
              title: '人員增補申請單'
            },
            {
              icon: 'fa-file',
              to: 'personnel-change-request',
              title: '人事異動單'
            },
            {
              icon: 'fa-file',
              to: 'personnel-and-payroll-change-request',
              title: '人事及薪資異動單'
            },
            {
              icon: 'fa-file',
              to: 'resignationand-handoverecord',
              title: '離職申請與移交確認紀錄'
            },
            {
              icon: 'fa-file',
              to: 'employee-probation-assessment',
              title: '員工試用期滿考核表'
            },
            {
              icon: 'fa-file',
              to: 'formance-evaluation',
              title: '年終績效評估表'
            },
          ]
        },
        {
          icon: 'fa-file',
          to: 'document',
          title: '管理',
          subNodes: [
            {
              icon: 'fa-file',
              to: 'business-trip-number-list',
              title: '出差編號填寫表'
            },
            {
              icon: 'fa-file',
              to: 'business-trip-application',
              title: '出差申請'
            },
            {
              icon: 'fa-file',
              to: 'business-trip-daily-report',
              title: '出差工作日報表'
            },
            {
              icon: 'fa-file',
              to: 'travel-entertainment-report',
              title: '差旅與交際費用申請單'
            },
            {
              icon: 'fa-file',
              to: 'official-seal-application',
              title: '公司大小印章用印申請'
            },
            // {
            //   icon: 'fa-file',
            //   to: 'order',
            //   title: '客戶訂單'
            // },
            // {
            //   icon: 'fa-file',
            //   to: 'customer-credit-application',
            //   title: '客戶超限放行單'
            // },
          ]
        },
        {
          icon: 'fa-file',
          to: 'document',
          title: '資訊',
          subNodes: [
            {
              icon: 'fa-file',
              to: 'IT-requirement-application',
              title: '資訊需求申請表'
            },
            {
              icon: 'fa-file',
              to: 'MES-permission-request',
              title: 'MES權限開放申請單'
            },
            {
              icon: 'fa-file',
              to: 'ERP-permission-request',
              title: 'ERP權限開放申請單'
            },
          ]
        },
        {
          icon: 'fa-file',
          to: 'document',
          title: '會計',
          subNodes: [
            {
              icon: 'fa-file',
              to: 'domesticInvoice-quarterly',
              title: '請購單(國內)'
            },
            {
              icon: 'fa-file',
              to: 'foreign-invoice-quarterly',
              title: '請購單(國外)'
            },
            {
              icon: 'fa-file',
              to: 'payment-application',
              title: '付款申請'
            },
            // {
            //   icon: 'fa-file',
            //   to: 'purchase-requisition',
            //   title: '請款單'
            // },
            {
              icon: 'fa-file',
              to: 'quotation',
              title: '報價單'
            },
            {
              icon: 'fa-file',
              to: 'domestic-sales-allowance',
              title: '銷貨折讓原因單(內銷)'
            },
          ]
        },
        {
          icon: 'fa-file',
          to: 'document',
          title: '生管',
          subNodes: [
            {
              icon: 'fa-file',
              to: 'BOM-usage-quantity-modify-item',
              title: 'BOM用量資料異動申請表'
            },
            {
              icon: 'fa-file',
              to: 'material-substitutes-application',
              title: '取替代料件異動申請表'
            },
            {
              icon: 'fa-file',
              to: 'product-number-modify-application',
              title: '品號異動申請單'
            },
          ]
        },
        {
          icon: 'fa-file',
          to: 'document',
          title: '總務',
          subNodes: [
            {
              icon: 'fa-file',
              to: 'purchase-requisition',
              title: '請購單'
            },
            {
              icon: 'fa-file',
              to: 'failure-report',
              title: '報修單'
            },
            {
              icon: 'fa-file',
              to: 'miscellaneous-charge-application',
              title: '雜費申請單'
            },
          ]
        },
        {
          icon: 'fa-file',
          to: 'document',
          title: '一般',
          subNodes: [
            {
              icon: 'fa-file',
              to: 'information-contact-form',
              title: '信息聯絡處理單'
            },
          ]
        },
        // {
        //   icon: 'fa-file',
        //   to: 'job-ticket-document',
        //   title: '工單'
        // },
        // {
        //   icon: 'fa-file',
        //   to: 'remittance-slip',
        //   title: '進款單'
        // },
        // {
        //   icon: 'fa-check-square',
        //   to: 'process',
        //   title: '文件簽核'
        // },
      ]
    },
    {
      icon: 'fa-table',
      to: 'document',
      title: '品管部',
      subNodes: [
        // {
        //   icon: 'fa-file',
        //   to: 'IQC-tag',
        //   title: '來料檢驗(標籤)'
        // },
        // {
        //   icon: 'fa-file',
        //   to: 'IQC-color-box',
        //   title: '來料檢驗(彩盒)'
        // },
        // {
        //   icon: 'fa-file',
        //   to: 'IQC-carton',
        //   title: '來料檢驗(紙箱)'
        // },
        // {
        //   icon: 'fa-file',
        //   to: 'IQC-paper-card',
        //   title: '來料檢驗(紙卡)'
        // },
        {
          icon: 'fa-file',
          to: 'check-record',
          title: '巡檢作業'
        },
        {
          icon: 'fa-file',
          to: 'typeA-production-check',
          title: '生產規格首件QC檢驗紀錄表(分條)'
        },
        {
          icon: 'fa-file',
          to: 'typeB-production-checkA',
          title: '生產規格首件QC檢驗紀錄表(複卷)'
        },
        {
          icon: 'fa-file',
          to: 'typeB-production-checkB',
          title: '生產規格首件QC檢驗紀錄表(切卷)'
        },
        {
          icon: 'fa-file',
          to: 'production-package-QC',
          title: '生產現場包裝首件QC檢查表'
        },
        {
          icon: 'fa-file',
          to: 'final-quality-inspection-report',
          title: '產品最終品質檢驗報告'
        },
        {
          icon: 'fa-file',
          to: 'humidity-check-record',
          title: '紙管濕度檢驗紀錄表'
        },
        {
          icon: 'fa-file',
          to: 'IQC-table',
          title: '來料檢驗'
        },
        {
          icon: 'fa-file',
          to: 'quality-handling-form',
          title: '品質異常處理單'
        },
        {
          icon: 'fa-file',
          to: 'quality-abnormality-cause-form',
          title: '品質異常原因調查表'
        },
        {
          icon: 'fa-file',
          to: 'physical-properties-testing',
          title: '物性檢測申請表'
        },
        {
          icon: 'fa-file',
          to: 'testing-report',
          title: '檢驗紀錄表'
        },
        {
          icon: 'fa-file',
          to: 'sample-notification-todo-form',
          title: '製作樣品'
        },
      ]
    },
    {
      icon: 'fa-file',
      to: 'proforma-invoice',
      title: '業務流程管理',
      subNodes: [
        {
          icon: 'fa-file',
          to: 'customer',
          title: '客戶管理'
        },
        {
          icon: 'fa-file',
          to: 'product',
          title: '產品管理'
        },
        {
          icon: 'fa-file',
          to: 'proforma-invoice',
          title: 'PI'
        },
        {
          icon: 'fa-file',
          to: 'job-ticke',
          title: '工單資料'
        },
        {
          icon: 'fa-file',
          to: 'invoice',
          title: '發票資料'
        },
        {
          icon: 'fa-file',
          to: 'packing-list',
          title: '包裝資料'
        },
        {
          icon: 'fa-file',
          to: 'remittance-slip',
          title: '進款單'
        },
        {
          icon: 'fa-file',
          to: 'job-ticket-document',
          title: '工單'
        },
        {
          icon: 'fa-file',
          to: 'sales-allowance',
          title: '銷貨折讓原因單'
        },
        {
          icon: 'fa-file',
          to: 'sample-notification-form',
          title: '製作樣品申請'
        },
      ]
    },
    {
      icon: 'fa-table',
      to: 'document',
      title: '文件簽核管理',
      subNodes: [
        {
          icon: 'fa-check-square',
          to: 'process',
          title: '文件簽核'
        },
        {
          icon: 'fa-book-open',
          to: 'in-process',
          title: '文件瀏覽'
        },
      ]
    },
    // {
    //   icon: 'fa-table',
    //   to: 'estimated-shipment',
    //   title: '訂單出貨管理',
    //   subNodes: [
    //     {
    //       icon: 'fa-table',
    //       to: 'estimated-shipment',
    //       title: '訂單預計出貨明細表'
    //     },
    //     {
    //       icon: 'fa-table',
    //       to: 'shipment',
    //       title: '出貨紀錄管理'
    //     },
    //   ]
    // },
    {
      icon: 'fa-calendar-alt',
      to: 'calendar',
      title: '行事曆管理'
    },
    {
      icon: 'fa-download',
      to: 'program',
      title: '檔案管理',
      subNodes: [
        {
          icon: 'fa-download',
          to: 'program',
          title: '程序書管理'
        },
        {
          icon: 'fa-download',
          to: 'product-files',
          title: '作業指導書管理'
        },
      ]
    },
    {
      icon: 'fa-university',
      to: 'accounts-receivable',
      title: '帳款管理',
      subNodes: [
        {
          icon: 'fa-university',
          to: 'accounts-receivable',
          title: '應收帳款管理'
        },
        {
          icon: 'fa-university',
          to: 'bank-account',
          title: '銀行帳款管理'
        },
      ]
    },
    {
      icon: 'fa-file',
      to: 'report',
      title: '報表管理',
      subNodes: [
        {
          icon: 'fa-university',
          to: 'report',
          title: '外銷業務報表'
        },
        {
          icon: 'fa-university',
          title: '人資報表',
          subNodes: [
            {
              icon: 'fa-university',
              to: 'performanceevaluationreport',
              title: '年終績效評估結算表'
            },
          ]
        },
      ]
    },
    // {
    //   icon: 'fa-list',
    //   to: 'unit-conversion2',
    //   title: '品號換算單位瀏覽2'
    // },
    // {
    //   icon: 'fa-sitemap',
    //   to: 'category',
    //   title: '類別管理'
    // },
    // {
    //   icon: 'fa-list',
    //   to: 'attribute',
    //   title: '屬性管理'
    // },
    // {
    //   icon: 'fa-list',
    //   to: 'attribute',
    //   title: '業務部'
    // },
    // {
    //   icon: 'fa-list',
    //   to: 'attribute',
    //   title: '管理部'
    // },
    // {
    //   icon: 'fa-list',
    //   to: 'attribute',
    //   title: '運籌部'
    // },
    // {
    //   icon: 'fa-list',
    //   to: 'attribute',
    //   title: '製造部'
    // },
    // {
    //   icon: 'fa-list',
    //   to: 'attribute',
    //   title: '品保部'
    // },
  ]
}

export default sitemap
