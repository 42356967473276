import { Model } from '@cloudfun/core'
import { main, Store as MainStore, State as MainState } from './main'
import configuration from './configuration'
import role from './role'
import user from './user'
import actionLog from './action-log'
import category from './category'
import bannerPosition from './banner-position'
import banner from './banner'
import pictureAlbum from './picture-album'
import picture from './picture'
import attribute from './attribute'
import attributeValue from './attribute-value'
import file from './file'
import importData from './import-data'
import position from './position'
import unitConversion from './unit-conversion'
import mapping1 from './mapping1'
import mapping2 from './mapping2'
import employee from './employee'
import employment from './employment'
import department from './department'
import estimatedShipment from './estimated-shipment'
import documentFlow from './document-flow'
import document from './document'
import calendarEvent from './calendar-event'
import proformaInvoice from './proforma-invoice'
import invoice from './invoice'
import packingList from './packing-list'
import jobTicke from './job-ticke'
import proformaInvoiceItem from './proforma-invoice-item'
import jobTickeItem from './job-ticke-item'
import productNumberPurchaseRecord from './product-number-purchase-record'
import manufacturerEstimatedPurchase from './manufacturer-estimated-purchase'
import product from './product'
import customer from './customer'
import shipment from './shipment'
import files from './files'
import machine from './machine'
import accountsReceivable from './accounts-receivable'
import bankAccount from './bank-account'
import iqcCategory from './iqc-category'
import iqcCategoryItem from './iqc-category-item'
import inProgressFlow from './in-progress-flow'
import signatureData from './signature-data'

const model = new Model('EmptyNext', { main, configuration, role, user, actionLog, category, bannerPosition, banner, pictureAlbum, picture, attribute, attributeValue, file, importData, position, unitConversion, mapping1, mapping2, employee, employment, department, estimatedShipment, documentFlow, document, calendarEvent, proformaInvoice, invoice, jobTicke, packingList, proformaInvoiceItem, jobTickeItem, manufacturerEstimatedPurchase, productNumberPurchaseRecord, product, customer, shipment, files, machine, accountsReceivable, bankAccount, iqcCategory, iqcCategoryItem, inProgressFlow, signatureData })

const backendServer = process.env.VUE_APP_BACKEND_URL
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true)
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`)
model.onLogin = (data) => model.clients.unauthorized.post('System/Login', {
  account: data.account,
  password: data.password,
  captchaToken: data.captchaToken,
  captcha: data.captcha
})
model.onLogout = () => model.clients.authorized.post('System/Logout')
model.onReloadUser = () => model.clients.authorized.post('System/CurrentUser')
model.onReloadEnums = () => model.clients.unauthorized.get('System/Enumerations')
model.onLog = (message) => model.clients.unauthorized.post('System/Log', message)

export type State = { main: MainState };
export type Store = MainStore<Pick<State, 'main'>>;

export default model
