import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import ErrorPage from "./views/error-page/Main.vue";
import { PolicyRule } from "@cloudfun/core/dist/policy";

const viewRotes: RouteRecordRaw[] = [
  // project views
  { path: "dashboard", component: () => import("./views/dashboard/Main.vue") },
  {
    path: "configuration",
    component: () => import("./views/configuration/Main.vue"),
    meta: { rule: "Configuration" }
  },
  {
    path: "action-log",
    component: () => import("./views/action-log/Main.vue"),
    meta: { rule: "ActionLog" }
  },
  // { path: 'role', component: () => import('./views/role/Main.vue'), meta: { rule: "Role" } },
  // { path: 'user', component: () => import('./views/user/Main.vue'), meta: { rule: "User" } },
  {
    path: "user-wizard",
    component: () => import("./views/user-wizard/Main.vue"),
    meta: { rule: new PolicyRule("Role").and("User") }
  },
  {
    path: "category",
    component: () => import("./views/category/Main.vue"),
    meta: { rule: "Category" }
  },
  {
    path: "banner",
    component: () => import("./views/banner/Main.vue"),
    meta: { rule: "Banner" }
  },
  {
    path: "album",
    component: () => import("./views/album/Main.vue"),
    meta: { rule: "Album" }
  },
  {
    path: "attribute",
    component: () => import("./views/attribute/Main.vue"),
    meta: { rule: "Attribute" }
  },
  {
    path: "import-info",
    component: () => import("./views/import-info/Main.vue"),
    meta: { rule: "ImportData" }
  },
  {
    path: "import-delivery",
    component: () => import("./views/import-delivery/Main.vue"),
    meta: { rule: "ImportData" }
  },
  {
    path: "position",
    component: () => import("./views/position/Main.vue"),
    meta: { rule: "Position" }
  },
  {
    path: "unit-conversion",
    component: () => import("./views/unit-conversion/Main.vue"),
    meta: { rule: "ProductNumberConversion" }
  },
  {
    path: "unit-conversion2",
    component: () => import("./views/unit-conversion2/Main.vue"),
    meta: { rule: "ProductNumberConversion" }
  },
  {
    path: "mapping1",
    component: () => import("./views/mapping1/Main.vue"),
    meta: {}
  },
  {
    path: "mapping2",
    component: () => import("./views/mapping2/Main.vue"),
    meta: {}
  },
  {
    path: "employee",
    component: () => import("./views/employee/Main.vue"),
    meta: { rule: "Employee" }
  },
  {
    path: "department",
    component: () => import("./views/department/Main.vue"),
    meta: { rule: "Department" }
  },
  {
    path: "estimated-shipment",
    component: () => import("./views/estimated-shipment/Main.vue"),
    meta: { rule: "OrderEstimatedShipmentList" }
  },
  {
    path: "document",
    component: () => import("./views/document/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "document-flow",
    component: () => import("./views/document-flow/Main.vue"),
    meta: { rule: "DocumentFlow" }
  },
  {
    path: "process",
    component: () => import("./views/process/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "in-process",
    component: () => import("./views/in-process/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "calendar",
    component: () => import("./views/calendar/Main.vue"),
    meta: { rule: "CalendarEvent" }
  },
  {
    path: "domesticInvoice-quarterly",
    component: () => import("./views/domesticInvoice-quarterly/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "foreign-invoice-quarterly",
    component: () => import("./views/foreign-invoice-quarterly/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "proforma-invoice",
    component: () => import("./views/proforma-invoice/Main.vue"),
    meta: { rule: "ProformaInvoice" }
  },
  {
    path: "invoice",
    component: () => import("./views/invoice/Main.vue"),
    meta: { rule: "Invoice" }
  },
  {
    path: "job-ticke",
    component: () => import("./views/job-ticke/Main.vue"),
    meta: { rule: "JobTicke" }
  },
  {
    path: "packing-list",
    component: () => import("./views/packing-list/Main.vue"),
    meta: { rule: "PackingList" }
  },
  {
    path: "product-number-purchase-record",
    component: () => import("./views/product-number-purchase-record/Main.vue"),
    meta: {}
  },
  {
    path: "manufacturer-estimated-purchase",
    component: () => import("./views/manufacturer-estimated-purchase/Main.vue"),
    meta: {}
  },
  {
    path: "product",
    component: () => import("./views/product/Main.vue"),
    meta: { rule: "Product" }
  },
  {
    path: "customer",
    component: () => import("./views/customer/Main.vue"),
    meta: { rule: "Customer" }
  },
  {
    path: "shipment",
    component: () => import("./views/shipment/Main.vue"),
    meta: { rule: "OrderEstimatedShipmentListRecord" }
  },
  {
    path: "BOM-usage-quantity-modify-item",
    component: () => import("./views/BOM-usage-quantity-modify-item/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "business-trip-application",
    component: () => import("./views/business-trip-application/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "failure-report",
    component: () => import("./views/failure-report/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "IT-requirement-application",
    component: () => import("./views/IT-requirement-application/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "material-substitutes-application",
    component: () => import("./views/material-substitutes-application/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "miscellaneous-charge-application",
    component: () => import("./views/miscellaneous-charge-application/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "official-seal-application",
    component: () => import("./views/official-seal-application/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "payment-application",
    component: () => import("./views/payment-application/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "personnel-assessment-monthly",
    component: () => import("./views/personnel-assessment-monthly/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "product-number-modify-application",
    component: () => import("./views/product-number-modify-application/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "purchase-requisition",
    component: () => import("./views/purchase-requisition/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "quotation",
    component: () => import("./views/quotation/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "order",
    component: () => import("./views/order/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "customer-credit-application",
    component: () => import("./views/customer-credit-application/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "check-record",
    component: () => import("./views/check-record/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "IQC-tag",
    component: () => import("./views/IQC-tag/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "IQC-color-box",
    component: () => import("./views/IQC-color-box/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "IQC-carton",
    component: () => import("./views/IQC-carton/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "IQC-paper-card",
    component: () => import("./views/IQC-paper-card/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "typeA-production-check",
    component: () => import("./views/typeA-production-check/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "typeB-production-checkA",
    component: () => import("./views/typeB-production-checkA/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "typeB-production-checkB",
    component: () => import("./views/typeB-production-checkB/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "production-package-QC",
    component: () => import("./views/production-package-QC/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "final-quality-inspection-report",
    component: () => import("./views/final-quality-inspection-report/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "sample-notice",
    component: () => import("./views/sample-notice/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "humidity-check-record",
    component: () => import("./views/humidity-check-record/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "job-ticket-document",
    component: () => import("./views/job-ticket-document/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "remittance-slip",
    component: () => import("./views/remittance-slip/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "program",
    component: () => import("./views/program/Main.vue"),
    meta: { rule: "File" }
  },
  {
    path: "MES-permission-request",
    component: () => import("./views/MES-permission-request/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "ERP-permission-request",
    component: () => import("./views/ERP-permission-request/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "formance-evaluation",
    component: () => import("./views/formance-evaluation/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "travel-entertainment-report",
    component: () => import("./views/travel-entertainment-report/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "personnel-supplementary",
    component: () => import("./views/personnel-supplementary/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "physical-properties-testing",
    component: () => import("./views/physical-properties-testing/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "testing-report",
    component: () => import("./views/testing-report/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "business-trip-number-list",
    component: () => import("./views/business-trip-number-list/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "sample-notification-form",
    component: () => import("./views/sample-notification-form/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "sample-notification-todo-form",
    component: () => import("./views/sample-notification-todo-form/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "personnel-and-payroll-change-request",
    component: () => import("./views/personnel-and-payroll-change-request/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "resignationand-handoverecord",
    component: () => import("./views/resignationand-handoverecord/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "employee-probation-assessment",
    component: () => import("./views/employee-probation-assessment/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "sales-allowance",
    component: () => import("./views/sales-allowance/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "domestic-sales-allowance",
    component: () => import("./views/domestic-sales-allowance/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "product-files",
    component: () => import("./views/product-files/Main.vue"),
    meta: { rule: "File" }
  },
  {
    path: "accounts-receivable",
    component: () => import("./views/accounts-receivable/Main.vue"),
    meta: { rule: "AccountsReceivable" }
  },
  {
    path: "bank-account",
    component: () => import("./views/bank-account/Main.vue"),
    meta: { rule: "BankAccount" }
  },
  {
    path: "report",
    component: () => import("./views/report/Main.vue"),
    meta: { rule: "Report" }
  },
  {
    path: "performanceevaluationreport",
    component: () => import("./views/report/PerformanceEvaluationReport.vue"),
    meta: { rule: "PerformanceEvaluationReport" }
  },
  {
    path: "IQC-category",
    component: () => import("./views/IQC-category/Main.vue"),
    meta: { rule: "IQCCategory" }
  },
  {
    path: "IQC-table",
    component: () => import("./views/IQC-table/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "quality-handling-form",
    component: () => import("./views/quality-handling-form/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "quality-abnormality-cause-form",
    component: () => import("./views/quality-abnormality-cause-form/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "information-contact-form",
    component: () => import("./views/information-contact-form/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "business-trip-daily-report",
    component: () => import("./views/business-trip-daily-report/Main.vue"),
    meta: { rule: "Document" }
  },
  // mid-one views
  {
    path: "midone-dashboard",
    component: () => import("./midone/views/dashboard/Main.vue")
  },
  {
    path: "midone-inbox",
    component: () => import("./midone/views/inbox/Main.vue")
  },
  {
    path: "midone-file-manager",
    component: () => import("./midone/views/file-manager/Main.vue")
  },
  {
    path: "midone-point-of-sale",
    component: () => import("./midone/views/point-of-sale/Main.vue")
  },
  {
    path: "midone-chat",
    component: () => import("./midone/views/chat/Main.vue")
  },
  {
    path: "midone-post",
    component: () => import("./midone/views/post/Main.vue")
  },
  {
    path: "midone-crud-data-list",
    component: () => import("./midone/views/crud-data-list/Main.vue")
  },
  {
    path: "midone-crud-form",
    component: () => import("./midone/views/crud-form/Main.vue")
  },
  {
    path: "midone-users-layout-1",
    component: () => import("./midone/views/users-layout-1/Main.vue")
  },
  {
    path: "midone-users-layout-2",
    component: () => import("./midone/views/users-layout-2/Main.vue")
  },
  {
    path: "midone-users-layout-3",
    component: () => import("./midone/views/users-layout-3/Main.vue")
  },
  {
    path: "midone-profile-overview-1",
    component: () => import("./midone/views/profile-overview-1/Main.vue")
  },
  {
    path: "midone-profile-overview-2",
    component: () => import("./midone/views/profile-overview-2/Main.vue")
  },
  {
    path: "midone-profile-overview-3",
    component: () => import("./midone/views/profile-overview-3/Main.vue")
  },
  {
    path: "midone-wizard-layout-1",
    component: () => import("./midone/views/wizard-layout-1/Main.vue")
  },
  {
    path: "midone-wizard-layout-2",
    component: () => import("./midone/views/wizard-layout-2/Main.vue")
  },
  {
    path: "midone-wizard-layout-3",
    component: () => import("./midone/views/wizard-layout-3/Main.vue")
  },
  {
    path: "midone-blog-layout-1",
    component: () => import("./midone/views/blog-layout-1/Main.vue")
  },
  {
    path: "midone-blog-layout-2",
    component: () => import("./midone/views/blog-layout-2/Main.vue")
  },
  {
    path: "midone-blog-layout-3",
    component: () => import("./midone/views/blog-layout-3/Main.vue")
  },
  {
    path: "midone-pricing-layout-1",
    component: () => import("./midone/views/pricing-layout-1/Main.vue")
  },
  {
    path: "midone-pricing-layout-2",
    component: () => import("./midone/views/pricing-layout-2/Main.vue")
  },
  {
    path: "midone-invoice-layout-1",
    component: () => import("./midone/views/invoice-layout-1/Main.vue")
  },
  {
    path: "midone-invoice-layout-2",
    component: () => import("./midone/views/invoice-layout-2/Main.vue")
  },
  {
    path: "midone-faq-layout-1",
    component: () => import("./midone/views/faq-layout-1/Main.vue")
  },
  {
    path: "midone-faq-layout-2",
    component: () => import("./midone/views/faq-layout-2/Main.vue")
  },
  {
    path: "midone-faq-layout-3",
    component: () => import("./midone/views/faq-layout-3/Main.vue")
  },
  {
    path: "midone-update-profile",
    component: () => import("./midone/views/update-profile/Main.vue")
  },
  {
    path: "midone-change-password",
    component: () => import("./midone/views/change-password/Main.vue")
  },
  {
    path: "midone-regular-table",
    component: () => import("./midone/views/regular-table/Main.vue")
  },
  {
    path: "midone-tabulator",
    component: () => import("./midone/views/tabulator/Main.vue")
  },
  {
    path: "midone-accordion",
    component: () => import("./midone/views/accordion/Main.vue")
  },
  {
    path: "midone-button",
    component: () => import("./midone/views/button/Main.vue")
  },
  {
    path: "midone-modal",
    component: () => import("./midone/views/modal/Main.vue")
  },
  {
    path: "midone-alert",
    component: () => import("./midone/views/alert/Main.vue")
  },
  {
    path: "midone-progress-bar",
    component: () => import("./midone/views/progress-bar/Main.vue")
  },
  {
    path: "midone-tooltip",
    component: () => import("./midone/views/tooltip/Main.vue")
  },
  {
    path: "midone-dropdown",
    component: () => import("./midone/views/dropdown/Main.vue")
  },
  {
    path: "midone-toast",
    component: () => import("./midone/views/toast/Main.vue")
  },
  {
    path: "midone-typography",
    component: () => import("./midone/views/typography/Main.vue")
  },
  {
    path: "midone-icon",
    component: () => import("./midone/views/icon/Main.vue")
  },
  {
    path: "midone-loading-icon",
    component: () => import("./midone/views/loading-icon/Main.vue")
  },
  {
    path: "midone-regular-form",
    component: () => import("./midone/views/regular-form/Main.vue")
  },
  {
    path: "midone-datepicker",
    component: () => import("./midone/views/datepicker/Main.vue")
  },
  {
    path: "midone-tail-select",
    component: () => import("./midone/views/tail-select/Main.vue")
  },
  {
    path: "midone-file-upload",
    component: () => import("./midone/views/file-upload/Main.vue")
  },
  {
    path: "midone-wysiwyg-editor",
    component: () => import("./midone/views/wysiwyg-editor/Main.vue")
  },
  {
    path: "midone-validation",
    component: () => import("./midone/views/validation/Main.vue")
  },
  {
    path: "midone-chart",
    component: () => import("./midone/views/chart/Main.vue")
  },
  {
    path: "midone-slider",
    component: () => import("./midone/views/slider/Main.vue")
  },
  {
    path: "midone-image-zoom",
    component: () => import("./midone/views/image-zoom/Main.vue")
  },
  // {
  //   path: "personnel-and-payroll-change-request",
  //   component: () => import("./views/personnel-and-payroll-change-request/Main.vue"),
  //   meta: { rule: "Document" }
  // },
  {
    path: "personnel-change-request",
    component: () => import("./views/personnel-change-request/Main.vue"),
    meta: { rule: "Document" }
  },
  {
    path: "employee-change-request",
    component: () => import("./views/employee-change-request/Main.vue"),
    meta: { rule: "Document" }
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/side-menu/Main.vue")
  },
  {
    path: "/simple-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/simple-menu/Main.vue")
  },
  {
    path: "/top-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/top-menu/Main.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login/Main.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/register/Main.vue")
  },
  { path: "/error-page", name: "error-page", component: ErrorPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage }
];

export default createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});
